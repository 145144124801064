import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/templates/layoutWithTitle"
import SEO from "../../components/seo"

import HeroCard from "../../components/organisms/heros/cardGeneric"

const IndexPage = ({ data }) => {

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout title="" fluid>
      <SEO title={frontmatter.title} />

      <HeroCard>
        <h1>{frontmatter.title}</h1>
        {frontmatter.date &&
          <date>Last updated: {frontmatter.date}</date>
        }
        <div className="mb-4"></div>

        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </HeroCard>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`

export default IndexPage
